/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable no-param-reassign */
import {
  CREATE_BOOKING,
  CHANGE_ROOM,
  JOIN_REMEMBERED,
  AUTO_ASSIGN_GUESTS,
  INCLUDE_BREAKFAST,
  INCLUDE_SMOKING,
  ADD_SUNDRY_ITEM,
  FAIL_GUEST_PROFILE,
  SUCCESS_GUEST_PROFILE,
  CHANGE_PAYMENT_METHOD,
  FAIL_CREDIT_CARD,
  SUCCESS_CREDIT_CARD,
  SUCCESS_WALLET,
  FAIL_WALLET,
  APPLY_PROMO_CODE,
  SUCCESS_PROMO_CODE,
  FAIL_PROMO_CODE,
  SUBMIT_BOOKING,
  SUCCESS_SUBMIT_BOOKING,
  FAIL_SUBMIT_BOOKING,
  RESEND_BOOKING_CONFIRMATION,
  SUCCESS_RESEND_BOOKING_CONFIRMATION,
  FAIL_RESEND_BOOKING_CONFIRMATION,
  INCLUDE_PET_FRIENDLY,
  CHANGE_MAYA_PAYMENT_METHOD,
} from '../constants';

export default function booking(state = null, action) {
  switch (action.type) {
    case CREATE_BOOKING: {
      return {
        ...state,
        ...action.payload,
        isSubmittingBooking: false,
      };
    }

    case CHANGE_ROOM: {
      const { roomType } = action.payload;
      state.roomTypes.some(type => {
        if (type.room.id === roomType.room.id) {
          type.room.available = roomType.room.available;
          type.numberOfRooms = roomType.numberOfRooms;
          return true;
        }
        return false;
      });
      return {
        ...state,
      };
    }

    case AUTO_ASSIGN_GUESTS: {
      const { roomTypes, numberOfRooms, adults, children } = action.payload;
      state.roomTypes = roomTypes;
      state.numberOfRooms = numberOfRooms;
      state.adults = adults;
      state.children = children;
      return {
        ...state,
      };
    }

    case JOIN_REMEMBERED: {
      state.joinRemembered = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          birthday: null,
        },
      };
    }

    case INCLUDE_BREAKFAST: {
      const includeBreakfast = action.payload;
      return {
        ...state,
        includeBreakfast,
      };
    }

    case INCLUDE_SMOKING: {
      const includeSmoking = action.payload;
      return {
        ...state,
        includeSmoking,
      };
    }

    case INCLUDE_PET_FRIENDLY: {
      const includePetFriendly = action.payload;
      return {
        ...state,
        includePetFriendly,
      };
    }

    case ADD_SUNDRY_ITEM: {
      const sundrySales = action.payload;
      return {
        ...state,
        sundrySales,
      };
    }

    case CHANGE_MAYA_PAYMENT_METHOD: {
      const mayaPayment = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        mayaPayment,
        errors,
        errorMessage,
      };
    }

    case CHANGE_PAYMENT_METHOD: {
      const payment = action.payload;
      const mayaPayment = '';
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        payment,
        mayaPayment,
        errors,
        errorMessage,
      };
    }

    case FAIL_GUEST_PROFILE: {
      const { errors, profile } = action.payload;
      return {
        ...state,
        errors,
        profile,
      };
    }

    case SUCCESS_GUEST_PROFILE: {
      const {
        profile,
        errorSubmitBooking,
        errorMessage,
        user,
      } = action.payload;
      const errors = errorSubmitBooking ? state.errors : [];
      const mayaPayment =
        state.hotel.country_code === 'PH' ? 'maya_wallet' : '';
      const b = {
        ...state,
        mayaPayment,
        profile,
        promoCode: state.promotion ? state.promoCode : '',
        errors,
        errorMessage,
        errorSubmitBooking,
        joinRemembered: user.isLoggedIn,
      };
      if (state.oldBooking) {
        b.joinRemembered = user.isLoggedIn && state.oldBooking.is_owner;
      }
      return b;
    }

    case FAIL_CREDIT_CARD: {
      const errors = action.payload;
      return {
        ...state,
        errors,
      };
    }

    case FAIL_WALLET: {
      const errors = action.payload;
      return {
        ...state,
        errors,
      };
    }

    case SUCCESS_WALLET: {
      const wallet = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        wallet,
        errors,
        errorMessage,
      };
    }

    case APPLY_PROMO_CODE: {
      const { promoCode, applyingForBin, supportedPayment } = action.payload;
      const promotion = null;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        promoCode,
        applyingForBin,
        promotion,
        supportedPayment,
        errors,
        errorMessage,
      };
    }

    case FAIL_PROMO_CODE: {
      let { errorMessage, errors } = action.payload;
      const promotion = null;
      let { promoCode } = state;
      if (state.applyingForBin) {
        promoCode = '';
        errorMessage = '';
        errors = [];
      }
      return {
        ...state,
        errors,
        errorMessage,
        promotion,
        promoCode,
      };
    }

    case SUCCESS_PROMO_CODE: {
      const { promotion, supportedPayment } = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        promotion,
        supportedPayment,
        errors,
        errorMessage,
      };
    }

    case SUCCESS_CREDIT_CARD: {
      const creditCard = action.payload;
      const errors = [];
      const errorMessage = '';
      return {
        ...state,
        creditCard,
        errors,
        errorMessage,
      };
    }

    case SUBMIT_BOOKING: {
      const isSubmittingBooking = true;
      const errorSubmitBooking = false;
      return {
        ...state,
        isSubmittingBooking,
        errorSubmitBooking,
      };
    }

    case SUCCESS_SUBMIT_BOOKING: {
      const bookingDetail = action.payload;
      const errorSubmitBooking = false;
      return {
        ...state,
        bookingDetail,
        errorSubmitBooking,
        creditCard: {
          cardHolder: '',
          cardNumber: '',
          expiry: '',
          cvc: '',
        },
        oldBooking: null,
      };
    }

    case FAIL_SUBMIT_BOOKING: {
      const { errorMessage, errors, oldEmail, oldBirthday } = action.payload;
      const isSubmittingBooking = false;
      const errorSubmitBooking = true;
      const { profile } = state;
      if (errors.indexOf('birthday') > -1) {
        profile.birthday = null;
      }
      if (errors.indexOf('email') > -1) {
        profile.email = null;
      }
      return {
        ...state,
        profile,
        errors,
        errorMessage,
        isSubmittingBooking,
        errorSubmitBooking,
        oldEmail,
        oldBirthday,
      };
    }

    case RESEND_BOOKING_CONFIRMATION: {
      return {
        ...state,
        resendBooking: {
          resending: true,
          errorMessage: '',
        },
      };
    }

    case SUCCESS_RESEND_BOOKING_CONFIRMATION: {
      return {
        ...state,
        resendBooking: {
          resending: false,
        },
      };
    }

    case FAIL_RESEND_BOOKING_CONFIRMATION: {
      const errorMessage = action.payload;
      return {
        ...state,
        resendBooking: {
          resending: false,
          errorMessage,
        },
      };
    }

    default: {
      return state;
    }
  }
}
